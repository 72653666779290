import { defineComponent, type PropType, computed, type CSSProperties, ref, onMounted, onUnmounted } from 'vue';
import './Table.sass';
import TableSkeleton from './TableSkeleton.vue';

export default defineComponent({
  name: 'TableRenderer',
  props: {
    headers: {
      type: Array as PropType<{ label: string; value: string; style?: object }[]>,
      required: true,
    },
    items: {
      type: Array as PropType<unknown[]>,
      required: true,
    },
    contained: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    layoutFixed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const headers = props.headers;
    const tableContainer = ref<HTMLElement | null>(null);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator?.userAgent); // Hide scrollbar on safari due to lack of customizability
    const showRightIndicator = ref(false);

    const updateScrollIndicators = async () => {
      if (!tableContainer.value) return;

      const { scrollLeft, scrollWidth, clientWidth } = tableContainer.value;
      showRightIndicator.value = scrollLeft + clientWidth < scrollWidth - 1;
    };

    const handleResize = async () => {
      updateScrollIndicators();
    };

    onMounted(() => {
      if (tableContainer.value) {
        tableContainer.value.addEventListener('scroll', updateScrollIndicators);
      }
      window.addEventListener('resize', handleResize);
      updateScrollIndicators();
    });

    onUnmounted(() => {
      if (tableContainer.value) {
        tableContainer.value.removeEventListener('scroll', updateScrollIndicators);
      }
      window.removeEventListener('resize', handleResize);
    });

    const classes = computed(() => [
      props.contained ? 'rounded-xl border border-solid border-neutral-200' : '',
      isSafari ? 'overflow-auto relative' : 'overflow-auto table-container-scroller relative',
    ]);

    return () => (
      <div class="relative">
        {props.loading && <TableSkeleton headers={headers} />}
        {!props.loading && (
          <>
            {showRightIndicator.value && <div class="table-scroll-indicator right" />}
            <div ref={tableContainer} class={classes.value} style="max-height: calc(100vh - 300px); z-index: 0;">
              <table
                class="w-full orderprotection"
                style={{ tableLayout: props.layoutFixed ? 'fixed' : 'auto', minWidth: 'max-content' }}
              >
                <thead class="text-xsm bg-neutral-100 sticky top-0 z-40">
                  <tr class="relative">
                    {headers.map(header => (
                      <th
                        key={header.value}
                        class="font-normal py-2 px-4 text-left"
                        style={header.style as CSSProperties}
                      >
                        {header.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{props.items.map(item => (ctx.slots.item ? ctx.slots.item({ item }) : null))}</tbody>
              </table>
            </div>
          </>
        )}

        {ctx.slots.footer && <div class="flex w-full justify-end">{ctx.slots.footer()}</div>}
      </div>
    );
  },
});
